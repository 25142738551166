<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="deviceGatewayInsh"
      @selection-change="handleSelectionChange">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('deviceGateway.onlineState')">
          <el-select
            v-model.trim="search.onlineStatus"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('100px', '100px') }"
            clearable>
            <el-option
              v-for="item in onlineStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <el-table-column type="selection" width="50" align="center"></el-table-column>
      <el-table-column prop="elevatorNo" :label="$t('elevator.no')" width="120" align="center">
        <template v-slot="scope">
          <router-link :to="'/elevator/' + scope.row.elevatorId" target="_blank">{{scope.row.elevatorNo}}</router-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="elevatorName"
        show-overflow-tooltip
        :label="$t('dayWeekMonthManger.elevatorName')"
        width="160"></el-table-column>
      <el-table-column
        prop="regNo"
        show-overflow-tooltip
        :label="$t('elevator.registerCode')"
        width="180"></el-table-column>
      <el-table-column
        prop="inshnId"
        :label="$t('deviceGateway.dtuCode')"
        width="120"
        align="center">
      </el-table-column>
      <el-table-column prop="onlineStatus" :label="$t('deviceGateway.dtuStatus')" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.onlineStatus === 0" type="info">
            {{$t("deviceGateway.dtuOffline")}}
          </el-tag>
          <el-tag v-if="scope.row.onlineStatus === 1" type="success">
            {{$t("deviceGateway.dtuOnline")}}
          </el-tag>
          <el-tag v-if="scope.row.onlineStatus === 2" type="success">
            {{$t("deviceGateway.call")}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="inshnIo"
        :label="$t('deviceGateway.deviceModel')"
        align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.inshnIo === 1">
            {{$t("deviceGateway.dtuOnline")}}
          </span>
          <span v-if="scope.row.inshnIo === 0">
            {{$t("deviceGateway.dtuOffline")}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="inshnSpeed"
        :label="$t('deviceGateway.elevatorSpeed')"
        align="center"></el-table-column>
      <el-table-column prop="inshnDoorType" :label="$t('deviceGateway.carDoorType')" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.inshnDoorType === 0">
            {{$t("deviceGateway.singleDoor")}}
          </span>
          <span v-if="scope.row.inshnDoorType === 1">
            {{$t("deviceGateway.doubleDoor")}}
          </span>
          <span v-if="scope.row.inshnDoorType === 2">
            {{$t("deviceGateway.smoke")}}
          </span>
          <span v-if="scope.row.inshnDoorType === 3">
            {{$t("deviceGateway.openDoorInPlace")}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="inshnDoorCnt" :label="$t('deviceGateway.judgmentsNumber')"  width="165" align="center"></el-table-column>
      <el-table-column prop="inshnDoorTime" :label="$t('deviceGateway.openDoorTime')" width="100" align="center"></el-table-column>
      <el-table-column prop="inshnNextTime" :label="$t('deviceGateway.horizontalSpacing')" width="100" align="center"></el-table-column>
      <el-table-column prop="inshnRegtel" :label="$t('deviceGateway.simCode')" width="100" align="center"></el-table-column>
      <el-table-column prop="iccid" :label="$t('deviceGateway.simICCID')" width="165" align="center"></el-table-column>
      <el-table-column
        prop="inshnFiltering"
        :label="$t('deviceGateway.sensor')"
        width="100"
        align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.inshnFiltering === 3">
            {{$t("deviceGateway.uModel")}}
          </span>
          <span v-if="scope.row.inshnFiltering === 10">
            {{$t("deviceGateway.other")}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="inshnNetctype"
        :label="$t('deviceGateway.networkFormat')"
        width="100"
        align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.inshnNetctype === 0">有线</span>
          <span v-if="scope.row.inshnNetctype === 1">2G</span>
          <span v-if="scope.row.inshnNetctype === 2">3G</span>
          <span v-if="scope.row.inshnNetctype === 3">4G</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="lastUpdateTime"
        :label="$t('deviceGateway.updateTime')"
        width="140"
        align="center"></el-table-column>
      <el-table-column :label="$t('elevatorNetApply.operate')" width="190" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="$refs.logList.open(scope.row.inshnId)">
            {{$t("device.record")}}
          </el-button>
          <el-button v-if="false" type="text" @click="$refs.editPage.open(scope.row.id,scope.row.regNo)">
            {{$t("elevatorNetApply.edit")}}
          </el-button>
          <el-button v-if="false" type="text" class="operateDelBtn" @click="handleDelete(scope.row)">
            {{$t("elevatorNetApply.delete")}}
          </el-button>
          <el-button v-if="scope.row.audioUrl&&scope.row.regNo" type="text" @click="handleAudio(scope.row)">
            {{$t("deviceGateway.audio")}}
          </el-button>
          <el-button v-if="scope.row.videoUrl&&scope.row.regNo" type="text" @click="handleVideo(scope.row)">
            {{$t("deviceGateway.video")}}
          </el-button>
          <el-button type="text" @click="handleMonitor(scope.row)">
            {{$t("deviceGateway.monitor")}}
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
    <device-gateway-insh-edit ref="editPage" @save-success="getList(-1)"></device-gateway-insh-edit>
    <device-log-list ref="logList"></device-log-list>
  </div>
</template>
<script>
  import DeviceGatewayInshEdit from "@/views/deviceGateway/deviceGatewayInsh/DeviceGatewayInshEdit";
  import DeviceLogList from "@/views/device/DeviceLogList.vue";

  const moduleName = "deviceGateway";
  export default {
    components: {DeviceGatewayInshEdit, DeviceLogList},
    data() {
      return {
        tableData: [],
        multiSelect: [],
        total: 0,
        currentPage: 1,
        adSearch: false,
        importLoading: false,
        loading: true,
        search: {
          filter: "",
          onlineStatus: "",
        },
        onlineStateOptions: [
          {value: 0, label: this.$t("deviceGateway.dtuOffline",)},
          {value: 1, label: this.$t("deviceGateway.dtuOnline")},
          {value: 2, label: this.$t("deviceGateway.call")},
        ],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNo) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNo);
      },
      handleSelectionChange(val) {
        this.multiSelect = val;
      },
      multiSelectSure() {
        this.$emit("multi-select", this.multiSelect);
      },
      sendMq(type, regNo) {
        let param = {
          topicType: type,
          regNo: regNo,
        };
        this.$http.post("deviceGatewayInsh/realData", param).then(res => {
          this.$message.success(this.$t("common.tip.operationSuccess"));
        });
      },
      handleAudio(row) {
        this.loading = true;
        this.$http.get("deviceGatewayInsh/audioUrl/" + row.regNo).then(res => {
          this.loading = false;
          if (res.data.data) {
            window.open(res.data.data);
          }
        }).catch((error) => {
          this.loading = false;
        });
      },
      handleVideo(row) {
        this.loading = true;
        this.$http.get("deviceGatewayInsh/videoUrl/" + row.regNo).then(res => {
          this.loading = false;
          if (res.data.data) {
            window.open(res.data.data);
          }
        }).catch((error) => {
          this.loading = false;
        });
      },
      handleMonitor(row) {
        window.open("#/singleMonitor/" + row.elevatorId);
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("elevator.dtuCode") + " " + row.dtuCode + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {type: "warning"}).then(() => {
          this.$http.delete("deviceGatewayInsh", row.id).then(() => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
