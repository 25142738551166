<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane :label="$t('elevator.esdt')" name="first">
      <device-list></device-list>
    </el-tab-pane>
    <el-tab-pane :label="$t('elevator.emt')" name="two">
      <device-insh-list></device-insh-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import DeviceList from "@/views/device/DeviceList";
  import DeviceInshList from "@/views/device/DeviceInshList";

  export default {
    components: { DeviceList,DeviceInshList },
    data() {
      return {
        activeName: "first",
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    },
  };
</script>
<style lang="scss" scoped></style>
